"use client";
import Button_Common from "@/Ui_Elements/Button_Common";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import style from "@/styles/Header.module.scss";
import { usePathname } from "next/navigation";

const menuData = [
  {
    title: "Home",
    link: "/",
    submenu: [],
  },
  {
    title: "Platform",
    submenuclass: "platformsubmenu",
    link: "#",
    submenu: [
      { title: "Overview", link: "/overview" },
      { title: "Features", link: "/features" },
      { title: "Integrations", link: "/integrations" },
      { title: "LMS Mobile App", link: "/mobile-app" },
      { title: "White Labelling", link: "#" },
    ],
  },
  // {
  //   title: "Solutions",
  //   submenuclass: "solutionssubmenu",
  //   link: "#",
  //   partition: true,
  //   submenu: [
  //     { title: "Use Cases", link: "#" },
  //     { title: "Industry", link: "#" },
  //     { title: "Corporate Training software", link: "/corporate-training-software" },
  //     { title: "Education", link: "#" },
  //     { title: "Sales Training", link: "/sales-training-software" },
  //     { title: "Higher Education", link: "#" },
  //     { title: "CyberSecurity Training", link: "/cybersecurity-training-software" },
  //     { title: "Vocational Centers", link: "#" },
  //     { title: "Employee Onboarding", link: "/employee-onboarding-software" },
  //     { title: "Universities", link: "#" },
  //     { title: "Software", link: "#" },
  //     { title: "HealthCare", link: "#" },
  //   ],
  // },
  {
    title: "Solutions",
    submenuclass: "solutionssubmenu",
    link: "#",
    partition: true,
    submenu: {
      leftmenu: {
        title: "Use Cases",
        menus: [
          { title: "Use Cases", link: "#" },
          { title: "Industry", link: "#" },
        ],
      },
      rightmenu: {
        title: "Industry",
        menus: [
          { title: "Corporate Training software", link: "/corporate-training-software" },
          { title: "Education", link: "#" },
        ],
      },
    },
  },
  {
    title: "Resources",
    submenuclass: "resourcessubmenu",
    link: "#",
    submenu: [
      { title: "Blogs", link: "https://www.vidyalayalms.com/blog" },
      { title: "Ebook", link: "#" },
      { title: "Webinar", link: "#" },
      { title: "Case Study", link: "#" },
      { title: "Clients", link: "#" },
    ],
  },
  {
    title: "Company",
    submenuclass: "companysubmenu",
    link: "#",
    submenu: [
      { title: "About Us", link: "/about-us" },
      { title: "Why Vidyalaya LMS", link: "/why-vidyalaya" },
      { title: "Contact Us", link: "/contact-us" },
    ],
  },
];

function Header() {
  const [isActive, setIsActive] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);
  const pathname = usePathname();
  const handleToggle = () => {
    setIsMenuVisible(!isMenuVisible);
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (menuId) => {
    if (!isMobile) {
      setActiveMenu(menuId); // Set the active menu on hover
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setActiveMenu(null); // Reset the active menu on mouse leave
    }
  };

  const handleMobileClick = (e, menuId) => {
    if (isMobile) {
      e.preventDefault(); // Prevent default link action on mobile
      setActiveMenu(activeMenu === menuId ? null : menuId); // Toggle active menu on click for mobile
    }
  };

  const handleBackClick = () => {
    setActiveMenu(null); // Reset the active menu on back click (for mobile)
  };

  const closeSidebar = () => {
    setIsMenuVisible(false);
    setIsActive(false);
    setActiveMenu(null); // Also close any open submenu
  };

  useEffect(() => {
    closeSidebar();
  }, [pathname]);

  return (
    <header className={style["headertag"]}>
      <div className={`${style["section-containe"]} container`}>
        <div className={`${style["headermaindiv"]} ${isActive ? style["active"] : ""}`}>
          <Link href="/" className={`${style["websitelogobox"]}`}>
            <Image
              className={`${style["headerlogo"]}`}
              src="/images/vid_lms_logo.png"
              alt="Vidyalaya LMS"
              width="200"
              height="0"
            />
          </Link>
          <div className={`${style["overlaymenu"]}`} onClick={closeSidebar}></div>

          <div className={`${style["mainboxheader"]}`} style={{ display: isMenuVisible ? "none" : "flex" }}>
            <nav className={`${style["headernavbar"]}`}>
              <ul className={`${style["navmenumaindiv"]}`}>
                <li
                  className={`${style["singlemenu"]} ${activeMenu === 0 ? style["active"] : ""}`}
                  onMouseEnter={() => handleMouseEnter(0)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link href="/" className={`${style["menulinks"]}`} onClick={(e) => handleMobileClick(e, 0)}>
                    Home
                  </Link>
                </li>
                <li
                  className={`${style["singlemenu"]} ${activeMenu === 1 ? style["active"] : ""} `}
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link href="#" className={`${style["menulinks"]}`} onClick={(e) => handleMobileClick(e, 1)}>
                    Platform{" "}
                    <Image
                      className={`${style["downarrow"]}`}
                      src="/images/down_arrow.svg"
                      alt="Vidyalaya LMS"
                      width={0}
                      height={0}
                    />
                  </Link>
                  <ul
                    className={`${style["submenubox"]} ${style["platformsubmenu"]} ${
                      activeMenu === 1 ? style["show"] : ""
                    }`}
                  >
                    <li>
                      <Link onClick={handleBackClick} href="#">
                        <Image
                          className={`${style["headerlogo"]}`}
                          src="/images/down_arrow.svg"
                          alt="Back"
                          width={18}
                          height={18}
                        />{" "}
                        Back
                      </Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/overview/lms-management-system">Overview</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/learning-management-system-training">LMS Training</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/integrations">Integrations</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/learning-management-system-application">LMS Application</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="">White Labelling</Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${style["singlemenu"]} ${activeMenu === 2 ? style["active"] : ""}`}
                  onMouseEnter={() => handleMouseEnter(2)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link href="#" className={`${style["menulinks"]}`} onClick={(e) => handleMobileClick(e, 2)}>
                    Solutions{" "}
                    <Image
                      className={`${style["downarrow"]}`}
                      src="/images/down_arrow.svg"
                      alt="Vidyalaya LMS"
                      width={0}
                      height={0}
                    />
                  </Link>
                  <ul
                    className={`${style["submenubox"]} ${style["solutionssubmenu"]} ${
                      activeMenu === 2 ? style["show"] : ""
                    }`}
                  >
                    <li>
                      <Link onClick={handleBackClick} href="#" className={`${style["backclass"]}`}>
                        <Image
                          className={`${style["headerlogo"]}`}
                          src="/images/down_arrow.svg"
                          alt="Back"
                          width={18}
                          height={18}
                        />{" "}
                        Back
                      </Link>
                    </li>
                    <div className={`${style["divide"]}`}>
                      <div className={`${style["partfirst"]}`}>
                        <h3 className={`${style["submenutitle"]}`}>Use Cases</h3>

                        <li onClick={closeSidebar}>
                          <Link href="/corporate-training-software">Corporate Training</Link>
                        </li>
                        <li>
                          <Link href="/sales-training-software">Sales Training</Link>
                        </li>
                        <li>
                          <Link href="/cybersecurity-training-software">CyberSecurity Training</Link>
                        </li>
                        <li>
                          <Link href="/employee-onboarding-software">Employee Onboarding</Link>
                        </li>
                      </div>
                      <div className={`${style["partsecond"]}`}>
                        <h3 className={`${style["submenutitle"]}`}>Industry</h3>
                        <li onClick={closeSidebar}>
                          <Link href="lms-for-healthcare">HealthCare</Link>
                        </li>
                        <li onClick={closeSidebar}>
                          <Link href="lms-for-higher-education">Higher Education</Link>
                        </li>
                        <li onClick={closeSidebar}>
                          <Link href="learning-management-system-for-schools">LMS for Schools</Link>
                        </li>
                        <li onClick={closeSidebar}>
                          <Link href="lms-for-coaching">LMS for Coaching</Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li
                  className={`${style["singlemenu"]} ${activeMenu === 3 ? style["active"] : ""}`}
                  onMouseEnter={() => handleMouseEnter(3)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link href="#" className={`${style["menulinks"]}`} onClick={(e) => handleMobileClick(e, 3)}>
                    Resources{" "}
                    <Image
                      className={`${style["downarrow"]}`}
                      src="/images/down_arrow.svg"
                      alt="Vidyalaya LMS"
                      width={0}
                      height={0}
                    />
                  </Link>
                  <ul
                    className={`${style["submenubox"]} ${style["resourcessubmenu"]} ${
                      activeMenu === 3 ? style["show"] : ""
                    }`}
                  >
                    <li>
                      <Link onClick={handleBackClick} href="#">
                        <Image
                          className={`${style["headerlogo"]}`}
                          src="/images/down_arrow.svg"
                          alt="Back"
                          width={18}
                          height={18}
                        />{" "}
                        Back
                      </Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/blog">Blogs</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="#">Ebook</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="#">Webinar</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="#">Case Study</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="#">Clients</Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${style["singlemenu"]} ${activeMenu === 4 ? style["active"] : ""}`}
                  onMouseEnter={() => handleMouseEnter(4)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link href="#" className={`${style["menulinks"]}`} onClick={(e) => handleMobileClick(e, 4)}>
                    Company{" "}
                    <Image
                      className={`${style["downarrow"]}`}
                      src="/images/down_arrow.svg"
                      alt="Vidyalaya LMS"
                      width={0}
                      height={0}
                    />
                  </Link>
                  <ul
                    className={`${style["submenubox"]} ${style["companysubmenu"]} ${
                      activeMenu === 4 ? style["show"] : ""
                    }`}
                  >
                    <li>
                      <Link onClick={handleBackClick} href="#">
                        <Image
                          className={`${style["headerlogo"]}`}
                          src="/images/down_arrow.svg"
                          alt="Back"
                          width={18}
                          height={18}
                        />{" "}
                        Back
                      </Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/about-us">About Us</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/ceo-message">CEO Message</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/why-vidyalaya">Why Vidyalaya LMS</Link>
                    </li>
                    <li onClick={closeSidebar}>
                      <Link href="/contact-us">Contact US</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className={`${style["rightloginpart"]}`}>
              <Link href="#" className={`${style["loginmenu"]}`}>
                Log in
              </Link>
              <Button_Common text={`Request For Demo`} href="/contact-us" />
            </div>
          </div>

          <div className={`${style["mobiletogglemenu"]} ${isActive ? style["active"] : ""}`} onClick={handleToggle}>
            <div className={`${style["menubarbox"]}`}>
              <span className={`${style["togglebar"]}`}></span>
              <span className={`${style["togglebar"]}`}></span>
              <span className={`${style["togglebar"]}`}></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
