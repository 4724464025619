import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\Components\\Common\\Header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\Components\\ScrollToTop.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\Context\\CommonAppScript.js");
;
import(/* webpackMode: "eager", webpackExports: ["PageState"] */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\Context\\PageState.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\node_modules\\next\\font\\local\\target.css?{\"path\":\"app\\\\layout.js\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-teachers\",\"src\":[{\"path\":\"./fonts/Teachers/Teachers-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/Teachers/Teachers-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/Teachers/Teachers-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./fonts/Teachers/Teachers-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/Teachers/Teachers-ExtraBold.woff2\",\"weight\":\"800\",\"style\":\"normal\"}]}],\"variableName\":\"teachers\"}");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\globle.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Loader.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Footer.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\projects\\Vidyalaya_LMS_Website_NEXTJS\\Ui_Elements\\css\\Button_Common.module.scss");
