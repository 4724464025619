"use client";
import { useState, useEffect, useRef } from "react";
import styles from "@/styles/ReadMore.module.scss";

const ReadMore = ({ children, className, maxLines, isHTML = false, colorcode, classname2 }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [lineLimit, setLineLimit] = useState(null);
  const [exceedsLimit, setExceedsLimit] = useState(false);
  const paragraphRef = useRef(null);


  const calculateLines = () => {
    if (paragraphRef.current) {
      const lineHeight = parseFloat(getComputedStyle(paragraphRef.current).lineHeight);
      const actualHeight = paragraphRef.current.scrollHeight; // Total height of the content
      const actualLines = Math.floor(actualHeight / lineHeight); // Calculate total lines
      return actualLines;
    }
    return 0;
  };

  useEffect(() => {
    const updateView = () => {
      const width = window.innerWidth;
      let lines = 8; // default for viewports < 768px

      //   if (width < 576) {
      //     lines = 3; // for viewports < 576px
      //   }
      if (width < 576 && maxLines[575]) {
        lines = maxLines[575]; // For viewports < 575px
      } else if (width < 768 && maxLines[767]) {
        lines = maxLines[767]; // For viewports < 768px
      }

      setLineLimit(lines);
      setIsMobileView(width < 768);

        // Measure actual lines after rendering
        const actualLines = calculateLines();
        setExceedsLimit(actualLines > lines);

      // Calculate if the paragraph exceeds the line limit
      // if (paragraphRef.current) {
      //   // debugger
      //   const totalHeight = paragraphRef.current.clientHeight;
      //   const lineHeight = parseFloat(window.getComputedStyle(paragraphRef.current).lineHeight);

      //   const numberOfLines = Math.round(totalHeight / lineHeight);
      //   console.log("ggggggg",numberOfLines, lines);
      //   setExceedsLimit(numberOfLines > lines);
      // }
    };

    updateView();
    window.addEventListener("resize", updateView);
    // console.log(exceedsLimit)
    return () => window.removeEventListener("resize", updateView);
  }, [maxLines, children]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <div className={styles.readMoreContainer}>
        <div
          ref={paragraphRef}
          className={`${styles.textContainer} ${className} ${isMobileView && isReadMore ? styles.clamped : ""} ${styles.readMoreContainer}`}
          style={{ WebkitLineClamp: isMobileView && isReadMore ? lineLimit : "unset" }}
        >
         {children}
        </div>
        
      {isMobileView && exceedsLimit && (
        <span className={`${classname2} ${!isReadMore ? styles.readMore : styles.readMoreabsolute}`} onClick={toggleReadMore} style={{backgroundColor: colorcode ? colorcode : "#f9f9f9"}} >
          {isReadMore ? (
            <>
              <span className={styles.threedot}>...</span>
              {""}
              <span className={styles.readmoretext}>Read More</span>
            </>
          ) : (
            <span className={styles.readmoretext}>Show Less</span>
          )}
        </span>
      )}
    </div>
  );
};

export default ReadMore;
