"use client"
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Navigation } from "swiper/modules";
// import "swiper/swiper.min.css";
// import "swiper/css/navigation";

const ResponsiveSwiper = ({ children, swiperConfig = {}, classname = "", desktop = false, windowwidth = 767 }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= windowwidth);
    };
    handleResize();
    // Set initial value and add resize listener
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (desktop) {
    return (
      <>
        <Swiper {...swiperConfig} modules={[A11y, Autoplay, Navigation, ...(swiperConfig.modules || [])]}>
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </Swiper>
      </>
    );
  } else {
    return (
      <>
        {isMobile ? (
          <>
            <Swiper {...swiperConfig} modules={[A11y, Autoplay, Navigation, ...(swiperConfig.modules || [])]}>
              {React.Children.map(children, (child, index) => (
                <SwiperSlide key={index}>{child}</SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : (
          <>{children}</>
        )}
      </>
    );
  }
};

export default ResponsiveSwiper;
