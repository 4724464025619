"use client"
import React, { useEffect, useRef, useState, useContext } from "react";
import PageContext from "@/Context/PageContext.js";
import $ from "jquery";

export const PageState = ({ children }) => {
  // const apiURL = "https://vidyalayanew.sapphiresolutions.net";
  // const apiURL = "https://localhost:44306";
  const LiveDomain = "www.vidyalayalms.com";
  const LiveAPI = "https://erpnew.onlinevidyalaya.net";
  // const apiURL = "http://nverpcore.sapphire.net";

  // const CurrentAPI = "https://localhost:44375"; // (new api local)
  const CurrentAPI = "https://erpnew.onlinevidyalaya.net"; // (new api live)
  // const apiURL2 = "https://erpnew.onlinevidyalaya.net";
  const [apiURL, setApiURL] = useState(CurrentAPI);

  useEffect(() => {
    if(window.location.hostname === LiveDomain){
      setApiURL(LiveAPI)
    } else{
      setApiURL(CurrentAPI);
    }
  }, [apiURL]);
  

  function CustomAlert(title, message, type) {
    const alertContainer = document.getElementById('alert-container');

    // Corrected typo: ovelayDiv -> overlayDiv
    const overlayDiv = document.createElement('div');
    overlayDiv.className = 'alertoverlaydiv';
    
    // Create the alert element

    const alertparent = document.createElement('div');
    alertparent.className = "alertparentdiv";

    const alert = document.createElement('div');
    alert.className = `alert ${type}`;
  
    // Icon for different types of alerts
    const icondiv = document.createElement('div');
    icondiv.className = 'alerticondiv';
    const icon = document.createElement('span');
    icon.className = 'icon';
    
    switch (type) {
      case 'success':
        icon.textContent = '✔';
        break;
      case 'failure':
        icon.textContent = '❌';
        break;
      case 'warning':
        icon.textContent = '⚠️';
        break;
      default:
        icon.textContent = 'ℹ️';
        break;
    }
  
    // Create content
    const textContent = document.createElement('div');
    textContent.className = 'text-content';
    const alertTitle = document.createElement('h3');
    alertTitle.textContent = title;
    const alertMessage = document.createElement('p');
    alertMessage.textContent = message;
  
    textContent.appendChild(alertTitle);
    textContent.appendChild(alertMessage);
  
    // Close button
    const closeButton = document.createElement('button');
    closeButton.className = 'close-btn';
    closeButton.textContent = '✖';
    closeButton.onclick = () => removeAlert(alertparent);
    overlayDiv.onclick = () => removeAlert(alertparent);
  
    // Append elements to the alert
    
    alert.appendChild(icondiv);
    alert.appendChild(textContent);
    alert.appendChild(closeButton);

    icondiv.appendChild(icon);
  
    alertparent.appendChild(overlayDiv);
    alertparent.appendChild(alert);
    // Append the alert to the alert container
    alertContainer.appendChild(alertparent);

  }
  
  function removeAlert(alert) {
    alert.classList.add('fade-out');
    setTimeout(() => {
      alert.remove();
    }, 500); // Duration of fade-out
  }


  // cookie code for phone intl
  const [countryCode, setCountryCode] = useState("");
  function getCookie(name) {
    var cookieName = name + "=";
    var cookieArray = document.cookie.split(";");
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }
  function setCookie(name, value, hours) {
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000);
    var expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + "; " + expires;
  }
  const getCountryCode = async () => {
    let getCountryCode = getCookie("countryDataVLMS");
    if (!getCountryCode) {
      try {
        const data = await fetch("https://ipapi.co/json");
        const result = await data.json();
        setCookie(
          "userIPStore",
          result.ip,
          8
        );
        setCookie(
          "countryDataVLMS",
          JSON.stringify(result.country_code.toLowerCase()),
          8
        );
        // setResultData(result)
        setCountryCode(result.country_code.toLowerCase());
      } catch (error) {
        setCookie("countryDataVLMS", JSON.stringify("us"), 8);
        // setResultData(result)
        setCountryCode("us");
      }
    } else {
      try {
        var countryInfo = JSON.parse(getCountryCode);
        if (countryInfo.ip) {
          setCountryCode(countryInfo.country_code.toLowerCase());
          setCookie(
            "countryDataVLMS",
            JSON.stringify(countryInfo.country_code.toLowerCase()),
            8
          );
        } else {
          setCountryCode(countryInfo.toLowerCase());
          setCookie(
            "countryDataVLMS",
            JSON.stringify(countryInfo.toLowerCase()),
            8
          );
        }
        // setResultData(countryInfo)
      } catch (error) {
        setCookie("countryDataVLMS", JSON.stringify("us"), 8);
        // setResultData(result)
        setCountryCode("us");
      }
    }
  };

  // device category function and serviceid function
  function checkDevice() {
    var screenIdentity = "Desktop";
    var screenWidth = screen.availWidth;
    if (screenWidth <= 600) {
      screenIdentity = "Mobile";
    } else if (screenWidth >= 600 && screenWidth < 1024) {
      screenIdentity = "Tablet";
    } else {
      screenIdentity = "Desktop";
    }
    return screenIdentity;
  }
  function hostfunction() {
    var inputUrl = getCookie("referralLinkDataVLMS");
    if (
      inputUrl != null &&
      inputUrl != undefined &&
      inputUrl != "undefined" &&
      inputUrl.trim() != ""
    ) {
      function getHostNameFromUrl(url) {
        try {
          var parsedUrl = new URL(url);
          return parsedUrl.host;
        } catch (error) {
          return url;
        }
      }
      var hostName = getHostNameFromUrl(inputUrl);
    } else {
      var hostName = inputUrl;
    }
    return hostName;
  }
  const GetServiceFormId = () => {
    var serviceformId = 0;
    var obj = $(".serviceform").parent();
    if (obj.is("#android-development")) {
      serviceformId = 1;
    } else if (obj.is("#iphone-app-development")) {
      serviceformId = 2;
    } else if (obj.is("#flutter-development")) {
      serviceformId = 3;
    } else if (obj.is("#react-native-development")) {
      serviceformId = 4;
    } else if (obj.is("#xamarin-development")) {
      serviceformId = 5;
    } else if (obj.is("#asp-net-development")) {
      serviceformId = 6;
    } else if (obj.is("#php-web-development")) {
      serviceformId = 7;
    } else if (obj.is("#java-development")) {
      serviceformId = 8;
    } else if (obj.is("#node-js-development")) {
      serviceformId = 9;
    } else if (obj.is("#angular-development")) {
      serviceformId = 10;
    } else if (obj.is("#react-js-development")) {
      serviceformId = 11;
    } else if (obj.is("#vue-js-development")) {
      serviceformId = 12;
    } else if (obj.is("#product-development")) {
      serviceformId = 13;
    } else if (obj.is("#cloud-development")) {
      serviceformId = 14;
    } else if (obj.is("#digital-marketing")) {
      serviceformId = 15;
    } else if (obj.is("#software-qa-testing")) {
      serviceformId = 16;
    } else if (obj.is("#ui-ux-design")) {
      serviceformId = 17;
    } else if (obj.is("#itoutsourcing")) {
      serviceformId = 18;
    } else if (obj.is("#hiredevelopers")) {
      serviceformId = 19;
    } else if (obj.is("#ms-service")) {
      serviceformId = 20;
    } else {
      serviceformId = 21;
    }
    return serviceformId;
  };
  // contact jobs and vacency forms
  const checkPageIdentity = (flag) => {
  
  };
  const ChangeFileName = () => {
    $("#customFile")
      .next()
      .next(".custom-file-label")
      .text($("#customFile")[0].files[0].name);
  };

  // common jobs code
  const checkIfNegative = (str2) => {
    /**
     * Checks if the given string represents a negative number.
     * 
     * @param {string} str2 - The string to be checked.
     * @returns {boolean} - Returns true if the string represents a negative number; otherwise, false.
     */
    const regexnegative = /^[0-9]*$/;
    return regexnegative.test(str2)
  }
 

  // common forms code
  const CommonSubmitForm = (identifypage, values) => {
    if (
        localStorage.VLMSgclidData != null &&
        localStorage.VLMSgclidData != undefined &&
        localStorage.VLMSgclidData != "undefined" &&
        localStorage.VLMSgclidData.trim() != ""
    ) {
        var sourceleadid = 1;
    } else {
        var sourceleadid = 2;
    }
    let userIPStore = getCookie("userIPStore");
    var formdata = {
        ...values,
        type: 8,
        url: window.location.href.split("#")[0],
        landingPage: getCookie("landingPageDataVLMS"),
        referralLink: getCookie("referralLinkDataVLMS"),
        apiId: identifypage,
        indexurl: localStorage.VLMSindexurl,
        gclidData: localStorage.VLMSgclidData,
        sourceleadid: sourceleadid,
        devicecategory: checkDevice(),
        referral: hostfunction(),
        userIP: userIPStore
    };

    // if (identifypage != 6) {
    if (identifypage == 1) {
        var commonformid = "contact-form";
    } else if (identifypage == 2) {
        var commonformid = "partner-form";
    } else if (identifypage == 3) {
        var commonformid = "ebook-form";
    } else if (identifypage == 4) {
        var commonformid = "contact_form_popup";
    } else if (identifypage == 5) {
        var commonformid = "brochure-form";
    } else {
        var commonformid = "subscriptionform";
    }
    var form_data = document.getElementById(commonformid);
    var country_data = form_data.querySelector(".selected-flag").title;
    if (country_data) {
        var code = country_data.split(": +");
    }
    var formdata = { ...formdata, countrycode: code[1] }; // add
    // var formdata = { ...formdata, countrycode: 91 }; // remove
    // }
    if (identifypage == 1) {
        var pagePath = "/contact-us-success";
    } else if (identifypage == 2) {
        var pagePath = "/become-patner-success";
    } else if (identifypage == 6) {
        var pagePath = "/subscription-success";
    } else if (identifypage == 5) {
        var pagePath = "/brochure-success";
    } else if (identifypage == 4) {
        var pagePath = "/popup-success";
    } else {
        var pagePath = "/ebook-success";
    }
    $(".commonformbtn").prop("disabled", true);
    $(".loading1").show();
    // console.log(formdata)
    // var ajaxurl = `${apiURL}/api/website/contactusadd`;
    var ajaxurl = `${apiURL}/Website/contactusadd`;

    // new api code
    var maxRetries = 2;
    var retryDelay = 2000;


   



    // -------------------\\\\\\ new api code\\\\\\\\\\\\\\\\\\\---------------
    function makeRequest(attempt) {
        $.ajax({
            url: ajaxurl,
            type: "POST",
            dataType: "json",
            headers: { "Access-Control-Allow-Origin": `${apiURL}` },
            data: formdata,
            success: function (data, textStatus, xhr, errorThrown) {
              console.log(data);
                if (data.statusCode == 101) {
                  console.log( "Request limit exceeded!",
                    "Sorry, You have already submitted multiple forms.",
                    "warning");
                    // swal(
                    //     "Request limit exceeded!",
                    //     "Sorry, You have already submitted multiple forms.",
                    //     "warning"
                    // );
                CustomAlert("Request limit exceeded!", "Sorry, You have already submitted multiple forms.", "warning");
                } else if (data.statusCode == 0) {
                    const formData = objectToFormData(formdata);
                    formData.append('StatusCode', data.statusCode);
                    console.log(formdata, textStatus, xhr.status, errorThrown);
                    failprocess(formdata, textStatus, xhr.status, errorThrown);
                } else {
                    handleSuccess(
                        data,
                        "Your message was sent, thank you. We will get back to you soon.",
                        identifypage
                    );
                }
            },
            error: function (xhr, textStatus, errorThrown) {
              console.log(xhr,textStatus,errorThrown);
                if (attempt < maxRetries && isTransientError(xhr.status)) {
                    setTimeout(function () {
                        makeRequest(attempt + 1);
                    }, retryDelay);
                } else {
                    failprocess(formdata, textStatus, xhr.status, errorThrown)
                    console.log(formdata, textStatus, xhr.status, errorThrown);
                }
            },
        });
    }
    makeRequest(0);

    function failprocess(formData, textStatus, xhrstatus, errorThrown) {
        const newformData = objectToFormData(formData);
        const logdatapass = {
            ...formData,
            textStatus: textStatus,
            xhr: xhrstatus,
            errorThrown: errorThrown,
        };

        function logWrite(logdatapass) {
            return new Promise((resolve, reject) => {
                $.post("../logs/lead_log_creater.php", logdatapass)
                    .done((data) => {
                        if (data == "1") {
                            handleSuccess("", "Your message has been successfully sent. We will get back to you very soon!", formData.apiId);
                        } else {
                            errormailsystem();
                        }
                        resolve(data);
                    })
                    .fail((jqXHR, textStatus, errorThrown) => {
                        errormailsystem();
                        reject(new Error("Log write failed: " + textStatus));
                    });
            });
        }

        // Usage
        logWrite(logdatapass);

        function errormailsystem() {
            datapass(newformData)
                .then((response) => {
                    if (response.statusCode == 200) {
                        handleSuccess("", "Your message has been successfully sent. We will get back to you very soon!", formData.apiId);
                    } else {
                        handleError({
                            title: "Process Failed!",
                            message: "Sorry, an error occurred while processing your request. Please try again later.",
                            type: "error",
                        })
                    }
                })
                .catch((error) => {
                    handleError({
                        title: "Process Failed!",
                        message: "Sorry, an error occurred while processing your request. Please try again later.",
                        type: "error",
                    })
                });
        }
    }
    function isTransientError(status) {
        console.log(`Checking if status ${status} is transient...`);
        return [0, 500, 502, 503, 504].includes(status);
    }

    function handleSuccess(data, successMessage, identifypage) {
      console.log("Good job!", successMessage, "success");
        // swal("Good job!", successMessage, "success");
        CustomAlert("Good job!", successMessage, "success");

        if (identifypage == 4) {
            $("#closebtn").trigger("click");
        }
        if (identifypage == 5) {
            const pdfUrl = "/webassets/pdf/vidyalaya_brochure.pdf";
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = "Vidyalaya Brochure.pdf";
            link.click();
        }
        $(".commonform").trigger("reset");
        gtag("event", "page_view", {
            page_path: pagePath, // Virtual page tracking
        });
        setTimeout(function () {
            $(".loading1").hide();
        }, 500);
        setTimeout(function () {
            $(".commonformbtn").prop("disabled", false);
        }, 500);
        localStorage.removeItem("VLMSindexurl");
        localStorage.removeItem("VLMSgclidData");
    }

    function handleError(errorInfo) {
      console.log(errorInfo.title, errorInfo.message, errorInfo.type);
        // swal();
        CustomAlert("Action Failed", "There was an error with your request.", "failure");
        setTimeout(function () {
            $(".loading1").hide();
        }, 500);
        setTimeout(function () {
            $(".commonformbtn").prop("disabled", false);
        }, 500);
    }

    function objectToFormData(obj, form, namespace) {
      let formData = form || new FormData();
      let formKey;

      for (let property in obj) {
          if (obj.hasOwnProperty(property)) {
              if (namespace) {
                  formKey = namespace + "[" + property + "]";
              } else {
                  formKey = property;
              }

              // if the property is an object, but not a File,
              // use recursion to handle nested objects
              if (
                  typeof obj[property] === "object" &&
                  !(obj[property] instanceof File)
              ) {
                  objectToFormData(obj[property], formData, formKey);
              } else {
                  formData.append(formKey, obj[property]);
              }
          }
      }

      return formData;
  }

    function datapass(newformData) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: "POST",
                url: "../lead_error.php",
                data: newformData,
                dataType: "json",
                contentType: false,
                cache: false,
                processData: false,
                success: function (response) {
                    resolve(response);
                },
                error: function (xhr, textStatus, errorThrown) {
                    console.log(xhr, "xhr");
                    reject({ statusCode: 500, message: xhr });
                },
            });
        });
    }

    $("#rcptcha").html("");
};

  const checkIsAlpha = (str) => {
    /**
      * Checks if the given string contains alphabetic characters.
      * @param {string} str - The string to be checked.
      * @returns {boolean} - Returns true if the string contains alphabetic characters; otherwise, false.
      */

    const regex = /\p{L}/gu;
    return regex.test(str);
  };

  const CommonValidateForm = (identifypage) => {
    let flag = true;
    if (identifypage == 1) {
        var commonformid = "contact-form";
    } else if (identifypage == 2) {
        var commonformid = "partner-form";
    } else if (identifypage == 3) {
        var commonformid = "ebook-form";
    } else if (identifypage == 4) {
        var commonformid = "contact_form_popup";
    } else if (identifypage == 5) {
        var commonformid = "brochure-form";
    } else {
        var commonformid = "subscriptionform";
    }

    var form = document.getElementById(commonformid);
    var inputs = form.querySelectorAll("input, textarea");
    const re = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    const codePatterns = [
      /<[^>]*>/g, // HTML tags
      /\/\/.*|\/\*[\s\S]*?\*\//g, // JavaScript/Java comments
      /#.*|'''.*'''|""".*"""/g, // Python comments
      /SELECT.*FROM|INSERT INTO/gi, // SQL keywords
      /function\s*\w*\s*\(|=>\s*\(/, // JavaScript function
      /[\{\}\[\];]/g, // Common code brackets
      /[{}<>]/g, // Common code brackets
      // Add more patterns for other languages if needed
    ];
    inputs.forEach(function (input) {
        if (input.value == "" || input.value == null) {
            flag = false;
        }
        if (input.name == "name") {
          if (codePatterns.some((pattern) => pattern.test(input.value))) {
            flag = false;
            $(`#${commonformid} #${input.name}_error`).html("Special characters not allowed");
          } else if(input.value == "" || input.value == null){
            flag = false;
            $(`#${commonformid} #${input.name}_error`).html("Name is required");
          } else if(!checkIsAlpha(input.value)){
            flag = false;
            $(`#${commonformid} #${input.name}_error`).html("At least one alphabet character is required");
          }  else{
            $(`#${commonformid} #${input.name}_error`).html("");
          }
        }
        if (input.type == "email" && !re.test(input.value)) {
            flag = false;
        }
        if (
            input.classList.contains("alphabetcheck") &&
            !checkIsAlpha(input.value)
        ) {
            flag = false;
        }
    });
    if (identifypage == 4) {
        if ($("#popupcontact").val() == null || $("#popupcontact").val() == "") {
            flag = false;
            $("#popup-errormsg").html("Please enter valid phone number");
        }
    }
    if (identifypage != 4 && identifypage != 6) {
        if (
            $("#contact_us_mobile_number").val() == null ||
            $("#contact_us_mobile_number").val() == ""
        ) {
            flag = false;
            $("#mobile-emsg").html("Please enter valid phone number");
        }
    }
    // if (identifypage == 1 || identifypage == 2 || identifypage == 3) {
    //     if (grecaptcha.getResponse() == null || grecaptcha.getResponse() == "") {
    //         flag = false;
    //         $("#rcptcha").html("");
    //         $("#rcptcha").html("Please verify recaptcha");
    //     } else {
    //         $("#rcptcha").html("");
    //     }
    // }
    if (identifypage == 6) {
        if (
            $("#newsletter_mobile_number").val() == null ||
            $("#newsletter_mobile_number").val() == ""
        ) {
            flag = false;
            $("#mobile-newsletter_error").html("Please enter valid phone number");
        }
    }

    return flag;
};
  const effectRan = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const dots = $(".owl-dot");
      if (dots.length > 0) {
        dots.each((index, element) => {
          $(element).attr("aria-label", `Slide ${index + 1}`);
        });
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  // captcha function
  var onloadCallback = function () {
    $("#rcptcha").html("");
  };
  return (
    <PageContext.Provider
      value={{
        checkPageIdentity,
        ChangeFileName,
        CommonSubmitForm,
        CommonValidateForm,
        // CommonSubmitJobs,
        getCountryCode,
        countryCode,
        onloadCallback,
        // CommonValidateJobs
      }}
    >
       {children}
    </PageContext.Provider>
  );
};

export const usePageState = () => {
  return useContext(PageContext);
};
